<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal()">
            <ion-icon
              slot="start"
              :icon="arrowBackOutline"
              color="dark"
            ></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Feedback</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="page_content">
        <form @submit.prevent="creaFeedback()">
          <ion-list class="fields">
            <!-- Data -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">
                  Data
                  <ion-text color="danger"><strong>*</strong></ion-text>
                </div>
                <div class="value">
                  <ion-datetime
                    name="date_start"
                    display-format="DD/MM/YYYY"
                    picker-format="DD/MM/YYYY"
                    min="2022"
                    max="2099"
                    slot="end"
                    doneText="OK"
                    cancelText="Annulla"
                    v-model="feedback.data"
                  ></ion-datetime>
                </div>
              </div>
            </ion-item>

            <!-- Data -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">
                  Tipologia
                  <ion-text color="danger"><strong>*</strong></ion-text>
                </div>
                <ion-select value="tipologia" v-model="feedback.tipologia">
                  <ion-select-option value="1">
                    Telefonata
                  </ion-select-option>
                  <ion-select-option value="2">
                    Visita
                  </ion-select-option>
                </ion-select>
              </div>
            </ion-item>

            <!-- Esito -->
            <ion-item lines="none" class="field ion-no-padding">
              <div class="field">
                <div class="field_title" position="floating">Esito</div>
                <div class="value">
                  <ion-textarea
                    type="text"
                    rows="3"
                    v-model="feedback.esito"
                    placeholder="Inserisci l'esito"
                  >
                  </ion-textarea>
                </div>
              </div>
            </ion-item>

            <div class="actions">
              <ion-button
                size="default"
                type="submit"
                expand="block"
                class="btn_richiesta"
              >
                Crea feedback
              </ion-button>
            </div>
          </ion-list>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  modalController,
  IonList,
  IonItem,
  IonDatetime,
  IonTextarea,
  IonText,
  IonIcon,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";

import { defineComponent, ref, reactive } from "vue";

import moment from "moment";

import { openToast } from "../services/toast";

import apiFeedback from "../services/feedbacks";

export default defineComponent({
  name: "ModalFeedback",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonText,
    IonIcon,
    IonSelect,
    IonSelectOption,
  },
  setup(props, context) {
    console.log(props);

    const date = new Date();

    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id; //set userID in user field

    const feedback = reactive({
      user: "", //from logged user
      cliente: props.id, //customer_id from props
      data: date.toISOString(),
      esito: "",
      tipologia: "",
    });

    const successResponse = ref(false);

    const fieldFlag = ref(false); //se tutti i campi sono ok diventa true e posso procedere con la creazione

    /**
     * Close modal after created new feedback
     */
    async function closeModalOnSubmit(successResponse, res) {
      if (successResponse.value) {
        //console.log(res);
        await modalController.dismiss(res);
      } else {
        await modalController.dismiss();
      }
    }

    /**
     * Handle close modal by clicking on Chiudi button
     */
    const closeModal = () => {
      modalController.dismiss();
    };

    /**
     * Create new feedback for the selected cliente
     */
    function creaFeedback() {
      const dataVisita = moment(feedback.data).format("YYYY-MM-DD");

      feedback.user = userID; //set user as logged user id

      if (!feedback.data) {
        openToast("La data è obbligatoria", "toast_danger");
        return;
      } else {
        fieldFlag.value = true;
      }

      if (!feedback.tipologia) {
        openToast("La tipologia è obbligatoria", "toast_danger");
        return;
      } else {
        fieldFlag.value = true;
      }

      //console.log("fieldFlag = ", fieldFlag.value);
      if (fieldFlag.value) {
        const data = new FormData();
        data.append("feedback_utente", userID); //logged user
        data.append("feedback_cliente", props.id); //customer id as prop
        data.append("feedback_data", dataVisita);
        data.append("feedback_esito", feedback.esito);
        data.append("feedback_tipologia", feedback.tipologia);

        // Display formData values
        /*         for (const value of data.entries()) {
          console.log(value[0] + ", " + value[1]);
        } */

        apiFeedback
          .saveFeedback(data)
          .then((response) => {
            if (response.data.status == 8) {
              openToast(response.data.message, "toast_danger");
            } else {
              const res = response.data.data[0];
              successResponse.value = true;
              closeModalOnSubmit(successResponse, res);
            }
          })
          .catch((error) => {
            // handle error
            console.error(error);
            openToast(
              "Errore durante la creazione del feedback",
              "toast_danger"
            );
          })
          .finally(() => {
            //console.log("fine creazione feedback");
          });
      }
    }

    return {
      feedback,
      creaFeedback,
      closeModal,
      userID,
      arrowBackOutline,
    };
  },
});
</script>

<style scoped>
.error_message {
  color: #eb445a;
}
.danger {
  color: #eb445a !important;
}

.example {
  border: 1px solid black;
}

.alert-wrapper {
  width: 90% !important;
}

/* New rules */
.page_content {
  width: 100%;
  position: relative;
  min-height: 100%;
  background: #f8fafc;
  padding: 16px;
}

.fields {
  width: 100%;
  border-radius: 4px;
  /*padding: 10px;*/
  padding: 16px;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 8px;
}

.field_title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #6b7280;
}

ion-item {
  --padding-start: 0;
  --padding-end: 0;
  margin-bottom: 4px;
  --ripple-color: transparent;
}
ion-datetime,
ion-select {
  max-width: 100%;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  --padding-start: 8px;
}

.picker-opt.picker-opt-selected,
.picker-prefix,
.picker-suffix {
  color: red;
}

.picker-button.sc-ion-picker-md,
.picker-button.activated.sc-ion-picker-md {
  color: red;
}

ion-input,
ion-textarea {
  --placeholder-color: #6b7280;
  --padding-start: 8px !important;
  --padding-end: 8px !important;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  margin-top: 0;
}

.btn_foto {
  --background: #0284c7;
  --background-activated: #0369a1;
  --background-hover: #0369a1;
  --background-focused: #0369a1;
  --box-shadow: none;
  --border-radius: 4px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  --ripple-color: none;
  --color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 16px;
}
.btn_richiesta {
  --background: #16a34a;
  --background-activated: #15803d;
  --background-hover: #15803d;
  --background-focused: #15803d;
  --box-shadow: none;
  --border-radius: 4px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  --ripple-color: none;
  --color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --padding-left: 0;
  --padding-right: 0;
  --inner-padding-end: 0;
}

.foto_container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  margin-top: 8px;
  margin-bottom: 16px;
}
.single_foto {
  margin-right: 16px;
  margin-bottom: 16px;
}
ion-thumbnail {
  --size: 120px;
  --border-radius: 4px;
}

.remove_photo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  background-color: #dc2626;
  border-radius: 4px;
}
.remove_photo span {
  color: #ffffff;
  padding: 4px;
  font-size: 12px;
}
</style>
