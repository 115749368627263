
import { IonSpinner, modalController } from "@ionic/vue";

import { defineComponent, ref } from "vue";
import moment from "moment";

import { openToast } from "../../services/toast";

import ModalAppuntamento from "../../components/ModalAppuntamento.vue";

import apiAppuntamenti from "../../services/appuntamenti";

export default defineComponent({
  name: "TabAppuntamenti",
  props: {
    customer: {
      type: String,
      required: true,
    },
  },
  components: {
    IonSpinner,
  },
  emits: ["nuovo-appuntamento"],
  setup(props, _context) {
    //console.log(props.customer);
    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;
    const loading = ref(false);
    const appuntamenti = ref([]);

    /**
     ** Open modal to create new appuntamento for this customer
     */
    async function newAppuntamento() {
      const modal = await modalController.create({
        component: ModalAppuntamento,
        componentProps: {
          id: props.customer,
        },
      });
      modal.onDidDismiss().then((detail) => {
        if (detail.data != undefined) {
          appuntamenti.value = [detail.data, ...appuntamenti.value];
          openToast("Appuntamento inserito con successo", "toast_success");
        }
      });
      return modal.present();
    }

    /**
     ** Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     */
    function dateFormat(date) {
      if (date && moment.isDate(new Date(date))) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    }
    /**
     ** Format date from YYYY/MM/DD HH:mm:ss to HH:mm
     */
    function hourFormat(date) {
      if (date && moment.isDate(new Date(date))) {
        return moment(date).format("HH:mm");
      } else {
        return "-";
      }
    }

    /**
     ** Remove html tag from events description
     */
    function parseHtmlEnteties(str) {
      if (str) {
        const strippedString = str.replace(/(<([^>]+)>)/gi, "");
        return strippedString.replace(
          /&#([0-9]{1,3});/gi,
          function (match, numStr) {
            const num = parseInt(numStr, 10); // read num as normal number
            return String.fromCharCode(num);
          }
        );
      }
    }

    /**
     ** Get appuntamenti for selected cliente
     */
    async function getAppuntamenti() {
      loading.value = true;
      try {
        appuntamenti.value = await apiAppuntamenti.getAppuntamentiCliente(
          userID,
          props.customer as string
        );
      } catch (error) {
        console.error(error);
        openToast(
          "Errore durante la richiesta degli appuntamenti",
          "toast_danger"
        );
      } finally {
        loading.value = false;
      }
    }

    getAppuntamenti();

    return {
      loading,
      appuntamenti,
      parseHtmlEnteties,
      dateFormat,
      hourFormat,
      newAppuntamento,
    };
  },
});
