import axios from 'axios';
const TOKEN = 'Bearer 64cda7184d52fee957124d505df02576';

export default {

    async getVenditeCliente(userId: string, customerId: string) {
        const data = new FormData();
        data.append("limit", "50");
        data.append("offset", "0");
        data.append("orderby", "vendite_data");
        data.append("orderdir", "desc");
        /*data.append("where[vendite_user]", userId);*/
        data.append("where[vendite_cliente]", customerId);
        data.append("depth", "3");

        const response = await axios.post('/search/vendite', data, {
            headers: {
                Authorization: TOKEN,
            },
        });

        //console.log(response);
        return response.data.data;
    },

    async saveVendita(vendita) {
        const response = await axios.post(`create/vendite`, vendita, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response;
    },
}