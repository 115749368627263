import axios from 'axios';
const TOKEN = 'Bearer 64cda7184d52fee957124d505df02576';

export default {

    async getFeedbacksCliente(userId: string, customerId: string) {
        const data = new FormData();
        data.append("offset", "0");
        data.append("orderby", "feedback_data");
        data.append("orderdir", "desc");
        //data.append("where[feedback_utente]", userId);*/
        data.append("where[feedback_cliente]", customerId);
        data.append("depth", "3");

        const response = await axios.post('/search/feedback', data, {
            headers: {
                Authorization: TOKEN,
            },
        });

        //console.log(response);
        return response.data.data;
    },

    async saveFeedback(feedback) {
        const response = await axios.post(`create/feedback`, feedback, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response;
    },
}