<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title>Dettaglio</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="dark"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="appuntamento">
                    <div class="field field_customer" v-if="customer.customers_company">
                        <div class="title">Azienda</div>
                        <div class="value">
                            {{ customer.customers_company }}
                        </div>
                    </div>
                    <div class="field" v-if="customer.customers_name">
                        <div class="title">Nome</div>
                        <div class="value">
                            {{ customer.customers_name }}
                        </div>
                    </div>
                    <div class="field" v-if="customer.customers_last_name">
                        <div class="title">Cognome</div>
                        <div class="value">
                            {{ customer.customers_last_name }}
                        </div>
                    </div>
                    <div class="field" v-if="customer.customers_phone">
                        <div class="title">Telefono</div>
                        <a :href="'tel:' + customer.customers_phone" class="value phone_link">
                            {{ customer.customers_phone }}
                        </a>
                    </div>
                    <div class="field" v-if="customer.customers_telefono_2">
                        <div class="title">Telefono 2</div>
                        <a :href="'tel:' + customer.customers_telefono_2" class="value phone_link">
                            {{ customer.customers_telefono_2 }}
                        </a>
                    </div>
                    <div class="field" v-if="customer.customers_province">
                        <div class="title">Provincia</div>
                        <div class="value">
                            {{ customer.customers_province }}
                        </div>
                    </div>
                    <div class="field" v-if="customer.customers_regione">
                        <div class="title">Regione</div>
                        <div class="value">
                            {{ customer.customers_regione }}
                        </div>
                    </div>
                    <div class="field_note">
                        <div class="title">Indirizzo</div>
                        <div class="value">
                            <a
                                :href="
                                    `https://maps.google.com/?q=${customer.customers_city ? customer.customers_city : ''} ${
                                        customer.customers_address ? customer.customers_address : ''
                                    } ${customer.customers_zip_code ? customer.customers_zip_code : ''}`
                                "
                                target="_blank"
                                class="mobile_link"
                            >
                                <ion-icon :icon="navigateOutline"></ion-icon>
                                {{ customer.customers_city }},
                                {{ customer.customers_address }}
                            </a>
                        </div>
                    </div>
                </div>

                <div class="tabs_container">
                    <div class="tabs_header">
                        <button @click="currentTab = 'TabAppuntamenti'" :class="{ tab_active: currentTab === 'TabAppuntamenti' }">
                            Appuntamenti
                        </button>
                        <button @click="currentTab = 'TabFeedback'" :class="{ tab_active: currentTab === 'TabFeedback' }">
                            Feedback
                        </button>
                        <button @click="currentTab = 'TabVendite'" :class="{ tab_active: currentTab === 'TabVendite' }">
                            Vendite
                        </button>
                    </div>
                    <keep-alive>
                        <component :is="currentTab" :customer="customer_id"></component>
                    </keep-alive>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    modalController,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";

import { arrowBackOutline, navigateOutline } from "ionicons/icons";

import moment from "moment";

import { openToast } from "../services/toast";

import ModalAppuntamento from "../components/ModalAppuntamento.vue";

import TabAppuntamenti from "@/components/tabs/TabAppuntamenti.vue";
import TabFeedback from "@/components/tabs/TabFeedback.vue";
import TabVendite from "@/components/tabs/TabVendite.vue";

export default defineComponent({
    name: "ClienteDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
        TabAppuntamenti,
        TabFeedback,
        TabVendite,
    },
    setup(props, _context) {
        const route = useRoute();
        const id = route.params.id; //customer_id by route params
        const loading = ref(false);
        const customer = ref([]);
        customer.value = { ...props.data };
        /*console.log(props.data);
    /onsole.log(customer.value);*/
        const currentTab = ref("TabAppuntamenti");

        //controllo per decidere da dove prendere id cliente se da customer (arrivo qua da tab1) o da events (arrivo qua da dettaglio appuntamento)
        const customer_id = ref(null);
        if (customer.value.customers_id) {
            customer_id.value = customer.value.customers_id;
        } else {
            customer_id.value = customer.value.events_customer;
        }

        /*
         * Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /*
         * Return '-' if a customer field is null or empty
         */
        function checkField(field) {
            if (!field || field == "") {
                return "-";
            } else {
                return field;
            }
        }

        /*
         * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
         */
        function dateFormat(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("DD/MM/YYYY");
            } else {
                return "-";
            }
        }
        /*
         * Format date from YYYY/MM/DD HH:mm:ss to HH:mm
         */
        function hourFormat(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("HH:mm");
            } else {
                return "-";
            }
        }

        /*
         * Open modal to create new appuntamento for this customer
         */
        async function newAppuntamento() {
            //console.log(customer.value.customers_id);
            const modal = await modalController.create({
                component: ModalAppuntamento,
                componentProps: {
                    id: customer.value.customers_id,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    openToast("Appuntamento inserito con successo", "toast_success");
                }
            });
            return modal.present();
        }

        return {
            id,
            loading,
            checkField,
            dateFormat,
            customer,
            arrowBackOutline,
            closeModal,
            hourFormat,
            navigateOutline,
            newAppuntamento,
            //tab component
            currentTab,
            //customer id
            customer_id,
        };
    },
});
</script>

<style scoped>
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new appuntamento btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}

.appuntamento {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.appuntamento .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.appuntamento .field.field_customer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #e2e8f0;
}
.appuntamento .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px 6px;
}
.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}

.mobile_link {
    text-decoration: none;
    color: #1279ac;
}

.field_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.customer_actions {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.btn_force_update {
    background-color: #0087ca;
    padding: 12px;
    margin-bottom: 12px;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.2);
}

/* Tabs container custom */
.tabs_container {
    width: 100%;
    margin-top: 16px;
}
.tabs_header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.tabs_header button {
    width: 100%;
    background-color: #0087ca;
    padding: 6px;
    margin: 4px;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.2);
}
.tabs_header button.tab_active {
    background-color: #005b88;
}

.phone_link {
    text-decoration: none;
    color: #1279ac;
}
</style>
