import axios from "axios";
const TOKEN = "Bearer 64cda7184d52fee957124d505df02576";

import { setCustomers } from "./localbase";

export default {
    async getClienti() {
        const data = new FormData();
        //data.append("limit", "300");
        data.append("offset", "0");
        data.append("orderby", "customers_last_name, customers_name");
        data.append("orderdir", "asc");

        const response = await axios.post("/search/customers", data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //qui salvo in indexeddb la collection Clienti nel db Clienti
        // con chiave id cliente e valore il cliente
        response.data.data.forEach((cliente) => {
            setCustomers(cliente);
        });
        //setCustomers(response.data.data);
        //localStorage.setItem('clienti', JSON.stringify(response.data.data));
        //console.log(response);
        return response.data.data;
    },

    async getClienteDetail(id: string) {
        const data = new FormData();
        data.append("where[customers_id]", id.toString());

        const response = await axios.post("/search/customers", data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response.data.data[0];
    },

    async getClienteSedi(id: string) {
        const data = new FormData();
        data.append("orderby", "customers_company");
        data.append("orderdir", "asc");
        data.append("where[customers_shipping_address_customer_id]", id.toString());

        const response = await axios.post("/search/customers_shipping_address", data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response.data.data;
    },

    async saveCliente(customer) {
        const response = await axios.post(`create/customers`, customer, {
            headers: {
                Authorization: TOKEN,
            },
        });
        console.log(response);
        return response;
    },

    async getMapClienti() {
        const data = new FormData();
        data.append("limit", "200");
        data.append("offset", "0");
        data.append("orderby", "customers_last_name, customers_name");
        data.append("orderdir", "asc");

        const response = await axios.post("/search/customers", data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        return response;
    },

    async searchCustomers(provincia: string, citta: string) {
        const data = new FormData();

        //provincia
        let where = "(";
        if (provincia) {
            where += `customers_province LIKE '%${provincia}%'`;
        }
        //citta
        if (citta) {
            if (where && provincia) {
                where += " AND ";
            }
            where += `customers_city LIKE '%${citta}%'`;
        }
        where += ")";
        //console.log(where);

        data.append("where[]", where);
        data.append("orderby", "customers_last_name");
        data.append("orderdir", "asc");

        const response = await axios.post("/search/customers", data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response;
    },
};
