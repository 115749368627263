
import { IonSpinner, modalController } from "@ionic/vue";

import { defineComponent, ref } from "vue";

import moment from "moment";

import { openToast } from "../../services/toast";

import ModalVendita from "../../components/ModalVendita.vue";

import apiVendite from "../../services/vendite";

export default defineComponent({
  name: "TabVendite",
  props: {
    customer: {
      type: String,
      required: true,
    },
  },
  components: {
    IonSpinner,
  },
  setup(props, _content) {
    //console.log(props.customer);
    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;
    const loading = ref(false);
    const vendite = ref([]);

    /**
     * * Open modal to create new appuntamento for this customer
     */
    async function newVendita() {
      const modal = await modalController.create({
        component: ModalVendita,
        componentProps: {
          id: props.customer,
        },
      });
      modal.onDidDismiss().then((detail) => {
        if (detail.data != undefined) {
          vendite.value = [detail.data, ...vendite.value];
          openToast("Vendita inserita con successo", "toast_success");
        }
      });
      return modal.present();
    }

    /**
     * * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     */
    function dateFormat(date) {
      if (date && moment.isDate(new Date(date))) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    }
    /**
     * * Format date from YYYY/MM/DD HH:mm:ss to HH:mm
     */
    function hourFormat(date) {
      if (date && moment.isDate(new Date(date))) {
        return moment(date).format("HH:mm");
      } else {
        return "-";
      }
    }
    /**
     * * Format price wuth currency
     */
    function priceFormat(price) {
      if (price) {
        return `€ ${price}`;
      } else {
        return "";
      }
    }

    /**
     * * Remove html tag from feedback esito
     */
    function parseHtmlEnteties(str) {
      if (str) {
        const strippedString = str.replace(/(<([^>]+)>)/gi, "");
        return strippedString.replace(/&#([0-9]{1,3});/gi, function(
          match,
          numStr
        ) {
          const num = parseInt(numStr, 10); // read num as normal number
          return String.fromCharCode(num);
        });
      }
    }

    /**
     * * Get feedback visite for selected cliente
     */
    async function getAppuntamenti() {
      loading.value = true;
      try {
        vendite.value = await apiVendite.getVenditeCliente(
          userID,
          props.customer as string
        );
      } catch (error) {
        console.error(error);
        openToast("Errore durante la richiesta delle vendite", "toast_danger");
      } finally {
        loading.value = false;
      }
    }

    getAppuntamenti();

    return {
      loading,
      vendite,
      parseHtmlEnteties,
      dateFormat,
      hourFormat,
      priceFormat,
      newVendita,
    };
  },
});
